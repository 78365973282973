import { MenuItem } from 'src/app/features/email/editor/axle-email-editor.component';

export const topBarEditorConfig = {
  plugins:
    'advcode advtemplate autolink emoticons image inlinecss link linkchecker lists mergetags help table advtable powerpaste tinymcespellchecker autocorrect',
  relative_urls: false,
  remove_script_host: false,
  branding: false,
  license_key:
    'T7LKCB4568D203387ED242B463C59BD9BABCD5733AA914AB66F8044B0F0685ED',
  toolbar:
    'mergetags | undo redo | aidialog aishortcuts | styles fontsize fontfamily | bold italic forecolor backcolor | link image emoticons table | align',
  toolbar_groups: {
    formatting: {
      icon: 'change-case',
      tooltip: 'Formatting',
      items:
        'styles fontsize fontfamily | bold italic forecolor backcolor | link image emoticons table'
    }
  },
  toolbar_sticky: false,
  object_resizing: 'img',
  images_file_types: 'jpg,jpeg,png',
  spellchecker_language: 'en_US',
  spellchecker_ignore_list: ['cancelled', 'Cancelled'],
  contextmenu: 'spellchecker',
  formats: {
    h1: { block: 'h1', styles: { fontSize: '24px' } },
    h2: { block: 'h2', styles: { fontSize: '20px' } },
    largetext: { block: 'p', styles: { fontSize: '20px' } },
    calltoaction: {
      selector: 'a',
      styles: {
        backgroundColor: '#335dff',
        padding: '12px 16px',
        color: '#ffffff',
        borderRadius: '4px',
        textDecoration: 'none',
        display: 'inline-block'
      }
    }
  },
  style_formats: [
    { title: 'Paragraph', format: 'p' },
    { title: 'Heading 1', format: 'h1' },
    { title: 'Heading 2', format: 'h2' },
    { title: 'Large text', format: 'largetext' }
  ],
  elementpath: false,
  visual: false,
  link_target_list: false,
  icons: 'bootstrap',
  menubar: false,
  toolbar_location: 'top' as 'top' | 'bottom',
  toolbar_mode: 'floating' as const,
  statusbar: false,
  content_style: `
              body {
                font-size: 14px;
              }
              p {
                margin: 0;
              }
            `,
  skin: 'oxide',
  content_css: 'default'
};

export function getEventMenuItems(singleMarketedOrg: boolean) {
  const eventMenuItems: MenuItem[] = [
    {
      title: 'Event Name',
      value: 'EVENT_NAME'
    },
    {
      title: 'Coordinating Org Name',
      value: 'COORDINATING_ORG_NAME'
    },
    {
      title: 'Event Date Range',
      value: 'EVENT_DATE_RANGE'
    },
    {
      title: 'Event Request Deadline',
      value: 'EVENT_REQUEST_DEADLINE'
    },
    {
      title: 'Event Registration Deadline',
      value: 'EVENT_REGISTRATION_DEADLINE'
    },
    {
      title: 'Event Contact Email',
      value: 'EVENT_CONTACT_EMAIL'
    }
  ];

  if (singleMarketedOrg) {
    eventMenuItems.push({
      title: 'Host Organization Name',
      value: 'HOST_ORG_NAME'
    });
    eventMenuItems.push({
      title: 'Hosts',
      value: 'HOST_NAMES'
    });
  }

  return eventMenuItems;
}
